/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #222;
    background-color: #efefef;
    padding: 2px 5px;
    border-radius: 5px;
}

.pull-right {
    float: right;
    display:flex;
    flex-flow:row;
    align-items:center;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
}

.btn-nuheat {
    color: white;
    background-color: #600303;
    border-color: #600303;
}

.bg-nuheat {
    background-color: #3c0101;
}

.bg-uwg5nvent {
    background-color: #88030A;
}

.btn-uwg5nvent {
    color: white;
    background-color: #A0353B;
    border-color: #A0353B;
}

.btn-uwg4 {
    color: white;
    background-color: #2b500c;
    border-color: #2b500c;
}

.bg-uwg4 {
    background-color: #143100;
}

.btn-senz {
    color: white;
    background-color: #0a0874;
    border-color: #0a0874;
}

.bg-senz {
    background-color: #030249;
}

.btn-owd5 {
    color: white;
    background-color: #800870;
    border-color: #800870;
}

.bg-owd5 {
    background-color: #520247;
}

.drop-shadow-sm {
    filter: drop-shadow(2px 3px 2px rgb(0 0 20 / 0.4));
}
